import noop from 'lodash/noop'
import uniqueId from 'lodash/uniqueId'
import React, {useEffect, useRef, useState} from 'react'

import Responsive from '../../../HOC/Responsive'
import {SectionProductsLoadingSkeleton} from '../../../common'
import Cards from '../../AllProductsV2/Products/Card'
import {LoadMoreButton} from './styles'
import {CONSTANTS} from 'src/constants/defaultConstants'

const PAGE_LIMIT = 8
function ProductsMobile({
  section,
  isMobile,
  onAddToCart = noop,
  onProductClick = noop,
  handleDeepLink = noop,
  sectionName,
}) {
  const [productsToShow, setProductsToShow] = useState(section.products)
  const scrollRef = useRef(null)

  const handleLoadMore = () => {
    scrollRef.current = window.scrollY
    setProductsToShow(
      productsToShow.concat(
        section.products.slice(
          productsToShow.length,
          productsToShow.length + PAGE_LIMIT
        )
      )
    )
  }

  useEffect(() => {
    setProductsToShow(section.products.slice(0, PAGE_LIMIT))
  }, [section.products])

  useEffect(() => {
    if (scrollRef.current && productsToShow.length > PAGE_LIMIT) {
      window.scrollTo(0, scrollRef.current)
    }
  }, [productsToShow])

  if (section.isGettingProducts) {
    return <SectionProductsLoadingSkeleton isMobile />
  }
  return (
    <div className="cards-wrap">
      {productsToShow.map((product, index) => {
        return (
          <div
            key={product?.id}
            className={
              section.label === 'Other Products'
                ? 'other-products-container'
                : 'cards-container'
            }
          >
            <Cards
              key={uniqueId()}
              isMobile={isMobile}
              card={product}
              onAddToCart={onAddToCart}
              onNavigation={onProductClick}
              handleDeepLink={handleDeepLink}
              position={index + 1}
              sectionName={sectionName}
              cta={section?.cta}
            />
          </div>
        )
      })}

      {productsToShow.length < section.products.length && (
        <LoadMoreButton onClick={handleLoadMore}>
          {CONSTANTS.PRODUCT_LOAD_MORE_TEXT}
        </LoadMoreButton>
      )}
    </div>
  )
}

export default Responsive(ProductsMobile)
